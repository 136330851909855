import React from "react";

export const Spinner = ({ fullscreen = false }) => {
  let className = "flex w-full justify-center items-center";

  if (fullscreen) {
    className = `${className} h-screen absolute`;
  } else {
    className = `${className} h-full`;
  }

  return (
    <div className={className}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
