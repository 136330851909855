import React, { useState, useEffect } from "react";
import * as qs from "query-string";
import axios from "axios";
import Login from "../components/Login";
import { Spinner } from "../components/Spinner";

const Auth = () => {
  const query = window.location.search;
  const { code } = qs.parse(query);

  const [successLogin, setSuccessLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authCode] = useState(code);
  const [logObject, setLogObject] = useState({ type: null, message: null });

  useEffect(() => {
    if (authCode.length === 36) {
      axios
        .get(
          `${process.env.REACT_APP_EKLASE_VERIFY_URL}/?authCode=${authCode}&redirectUri=${process.env.REACT_APP_EKLASE_REDIRECT_URL}`
        ) // backend call
        .then(res => {
          // get response from back-end
          const { data } = res;

          if (
            data.type === "OAuthException" &&
            data.message === "Error validating verification code."
          ) {
            setLogObject({
              type: "error",
              message: "Kļūda pārbaudot verifikācijas kodu."
            });
          }

          if (
            data.error ===
            "Your school account is not allowed to login this server."
          ) {
            setLogObject({
              type: "error",
              message: "Jūsu skolas kontam nav iespējams pieslēgties sistēmai."
            });
          }

          if (
            data.error ===
            "Your account person type is not allowed to login this server."
          ) {
            setLogObject({
              type: "error",
              message:
                "Jūsu konta veidam/tipam nav iespējams pieslēgties sistēmai."
            });
          }

          if (data.error === "Teacher not found in local users database.") {
            setLogObject({
              type: "error",
              message:
                "Jūsu konts nav reģistrēts iekšējās sistēmas datu bāzē. Sazinieties ar vietnes administratoru konta pieejas izveidošanai."
            });
          }

          if (data.error === "Your account is disabled in local database.") {
            setLogObject({
              type: "error",
              message:
                "Jūsu konts ir bloķēts iekšējās sistēmas datu bāzē. Sazinieties ar vietnes administratoru konta pieejas atjaunošanai."
            });
          }

          if (data.accessToken) {
            // redirect to php-app for auto login
            window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN_URL}/?accessToken=${data.accessToken}&personType=${data.personType}`;
            setSuccessLogin(true);
            setLogObject({
              type: "success",
              message: "Jūsu esat veiksmīgi pieslēdzies sistēmai."
            });
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setLogObject({
            type: "error",
            message:
              "Servera darbības vai tīkla kļūda. Sazinieties ar vietnes administratoru."
          });
          throw err;
        });
    }
  }, [authCode]);

  if (loading) {
    return <Spinner fullscreen={true} />;
  }

  if (!loading && successLogin) {
    // console.log("Redirect to Laravel auto login...");
    if (logObject.type === "success") {
      return <Spinner fullscreen={true} />;
    }
  }

  return (
    <>
      {!loading && logObject.type && (
        <Login withLog={true} logObject={logObject} />
      )}
    </>
  );
};

export default Auth;
