import React from "react";
import { Router } from "@reach/router";

import Home from "./pages/Home";
import Auth from "./pages/Auth";

function App() {
  return (
    <div className="App bg-background-primary antialiased">
      <Router>
        <Home path={`${process.env.REACT_APP_BASE_PUBLIC_URL}/`} />
        <Auth path={`${process.env.REACT_APP_BASE_PUBLIC_URL}/auth`} />
      </Router>
    </div>
  );
}

export default App;
