import React from "react";

export const LogMessage = ({ type, message }) => {
  const iconError = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="fill-current flex-shrink-0 text-white"
    >
      <path d="M6.38 14H4a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h11.5c1.2 0 2.3.72 2.74 1.79l3.5 7 .03.06A3 3 0 0 1 19 15h-5v5a2 2 0 0 1-2 2h-1.62l-4-8zM8 12.76L11.62 20H12v-7h7c.13 0 .25-.02.38-.08a1 1 0 0 0 .55-1.28l-3.5-7.02A1 1 0 0 0 15.5 4H8v8.76zM6 12V4H4v8h2z" />
    </svg>
  );

  const iconSuccess = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="fill-current flex-shrink-0 text-white"
    >
      <path d="M17.62 10H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H8.5c-1.2 0-2.3-.72-2.74-1.79l-3.5-7-.03-.06A3 3 0 0 1 5 9h5V4c0-1.1.9-2 2-2h1.62l4 8zM16 11.24L12.38 4H12v7H5a1 1 0 0 0-.93 1.36l3.5 7.02a1 1 0 0 0 .93.62H16v-8.76zm2 .76v8h2v-8h-2z" />
    </svg>
  );

  let icon = "";
  let classNames = "w-full px-10 py-3 text-white";

  if (type === "error") {
    classNames += " bg-red-500";
    icon = iconError;
  }

  if (type === "success") {
    classNames += " bg-teal-500";
    icon = iconSuccess;
  }

  return (
    <div className={classNames}>
      <div className="flex items-center">
        {icon}
        <span className="ml-2">{message}</span>
      </div>
    </div>
  );
};
