import React from "react";

export const UnsplashImageTag = ({ image, className }) => {
  const { urls, description } = image;
  return (
    <img
      src={urls.regular}
      alt={description ? description : "Unsplash image"}
      srcSet={`${urls.small} 400w, ${urls.regular} 1080w`}
      className={className}
    />
  );
};
