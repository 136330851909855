import React, { useState, useEffect } from "react";
import axios from "axios";
import { LogMessage } from "./LogMessage";
import { Spinner } from "./Spinner";
import { UnsplashImageTag } from "./UnsplashImageTag";

function Login({ withLog = false, logObject = {} }) {
  const [unsplashImage, setUnsplashImage] = useState(null);

  useEffect(() => {
    const fetchRandomImage = async () => {
      const unsplashImageJson = await axios.get(
        `${process.env.REACT_APP_UNSPLASH_RANDOM_IMAGE_URL}`
      );

      setUnsplashImage(
        unsplashImageJson.data.json && unsplashImageJson.data.json.length > 0
          ? unsplashImageJson.data.json[0]
          : null
      );
    };
    fetchRandomImage();
  }, []);

  return (
    <>
      {withLog && (
        <LogMessage type={logObject.type} message={logObject.message} />
      )}
      {unsplashImage && (
        <div className="w-full h-full absolute overflow-hidden">
          <UnsplashImageTag
            image={unsplashImage}
            className="object-cover w-full h-full absolute"
          />
          <div
            className="w-full h-full bg-full relative"
            style={{
              backgroundImage: `linear-gradient(hsla(0,0%,100%,.75),hsla(0,0%,100%,1) 30%, #f5f8fa)`
            }}
          ></div>
        </div>
      )}
      <div className="relative max-w-5xl mx-auto md:flex h-full justify-center px-4">
        <div className="pt-4 sm:pt-16">
          <div className="text-xs text-gray-700 uppercase font-semibold tracking-wider text-center mb-2 mt-2">
            {process.env.REACT_APP_DOMAIN}
          </div>
          <div className="flex flex-wrap border-0 sm:border-t sm:border-l md:flex-row-reverse rounded-lg overflow-hidden shadow-md">
            <div className="w-full md:w-1/2">
              {!unsplashImage && <Spinner />}
              {unsplashImage && (
                <div className="w-full h-full relative">
                  <UnsplashImageTag
                    image={unsplashImage}
                    className="object-cover w-full h-48 md:h-96"
                  />
                  {unsplashImage.user && (
                    <div className="absolute bottom-0 w-full text-center gradient pt-16 pb-4 text-gray-300 text-sm">
                      <span>
                        Foto:{" "}
                        <a
                          className="hover:underline"
                          href={`${unsplashImage.user.links.html}/?utm_source=Ogres%20s%C4%81kumskola%20Login%20page&amp;utm_medium=referral`}
                          target="_blank"
                          title={unsplashImage.user.name}
                          rel="noopener noreferrer"
                        >
                          @{unsplashImage.user.name}
                        </a>{" "}
                        —{" "}
                        <a
                          className="hover:underline"
                          href="https://unsplash.com?utm_source=Ogres%20s%C4%81kumskola%20Login%20page&amp;utm_medium=referral"
                          target="_blank"
                          title="Unsplash"
                          rel="noopener noreferrer"
                        >
                          Unsplash
                        </a>
                        .
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="w-full md:w-1/2 bg-white px-6 py-6 md:pt-12 md:px-10">
              <span className="block text-sm uppercase text-gray-600 tracking-tight leading-none">
                Ogres sākumskolas
              </span>
              <h1 className="text-2xl text-gray-900 mb-4 sm:text-3xl">
                Informāciju sistēma
              </h1>
              <p className="text-gray-700 mb-4 md:mb-6">
                Piekļuve sistēmā ir nodrošināta tikai tiem Ogres sākumskolas
                skolotājiem un skolēnu vecākiem, kuriem ir derīgs{" "}
                <a
                  href="https://www.e-klase.lv"
                  target="_blank"
                  title="e-klase.lv"
                  rel="noopener noreferrer"
                  className="text-blue-700 underline hover:text-blue-900"
                >
                  e-klase.lv
                </a>{" "}
                konts.
              </p>
              <p className="pt-2 sm:pt-3">
                <a
                  href={process.env.REACT_APP_EKLASE_OAUTH_URL}
                  title="Pieslēgties ar E-klase pasi"
                  className="bg-blue-600 items-center inline-flex px-3 py-2 pr-4 rounded hover:bg-blue-700 focus:bg-blue-800 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-current text-white"
                  >
                    <path d="M11.85 17.56a1.5 1.5 0 0 1-1.06.44H10v.5c0 .83-.67 1.5-1.5 1.5H8v.5c0 .83-.67 1.5-1.5 1.5H4a2 2 0 0 1-2-2v-2.59A2 2 0 0 1 2.59 16l5.56-5.56A7.03 7.03 0 0 1 15 2a7 7 0 1 1-1.44 13.85l-1.7 1.71zm1.12-3.95l.58.18a5 5 0 1 0-3.34-3.34l.18.58L4 17.4V20h2v-.5c0-.83.67-1.5 1.5-1.5H8v-.5c0-.83.67-1.5 1.5-1.5h1.09l2.38-2.39zM18 9a1 1 0 0 1-2 0 1 1 0 0 0-1-1 1 1 0 0 1 0-2 3 3 0 0 1 3 3z" />
                  </svg>{" "}
                  <span className="ml-2 text-white text-sm hidden uppercase font-semibold sm:inline-flex">
                    Pieslēgties ar E-klase pasi
                  </span>
                  <span className="ml-2 text-white uppercase font-semibold sm:hidden">
                    Pieslēgties
                  </span>
                </a>
              </p>
            </div>
          </div>
          <div className="mt-5 text-sm text-gray-600 text-center pb-4">
            <p>
              &copy; Visas tiesības aizsargātas.{" "}
              <span className="block sm:inline-block">
                Ogres sākumskola 2002. — {`${new Date().getFullYear()}`}.
              </span>
            </p>
            <p>
              Spēcināts ar{" "}
              <a
                href="https://www.e-klase.lv"
                target="_blank"
                title="e-klase.lv"
                rel="noopener noreferrer"
                className="underline hover:text-gray-700"
              >
                e-klase.lv
              </a>{" "}
              pasi.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
